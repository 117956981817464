import axios from "axios";
import config from "../../config";

export const fetchQuestionsAPI = ({ id, page, perPage }) =>
  axios.get(`${config.api}/muntra-questions`, {
    params: {
      order: "desc",
      page: page || 1,
      per_page: perPage || 15,
      sort_by: "last_activity_date",
      include: "accepted_answer,answers,owner,tags",
      tag_ids: id,
    },
  });

export const fetchQuestionByIdApi = (id) =>
  axios.get(`${config.api}/muntra-questions/${id}`, {
    params: {
      include: "accepted_answer.owner,answers.owner,owner,tags",
    },
  });

export const fetchSearchResultAPI = (search) =>
  axios.get(`${config.api}/muntra-questions`, {
    params: {
      query: search,
    },
  });
