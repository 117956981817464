import { questionsActions as actions } from "./actions";

const initialState = {
  questions: [],
  question: {},
  searchResults: [],
  loading: false,
  searchLoading: false,
  error: "",
  searchError: "",
};

export const questionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.GET_QUESTIONS:
    case actions.GET_QUESTION:
    case actions.GET_QUESTIONS_BY_TAG:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_QUESTIONS_SUCCESS:
    case actions.GET_QUESTIONS_BY_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: action.payload,
      };
    case actions.GET_QUESTIONS_ERROR:
    case actions.GET_QUESTION_ERROR:
    case actions.GET_QUESTIONS_BY_TAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        question: action.payload,
      };
    case actions.GET_SEARCH_RESULT:
      return {
        ...state,
        searchLoading: true,
      };
    case actions.GET_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        searchResults: action.payload,
      };
    case actions.GET_SEARCH_ERROR:
      return {
        ...state,
        searchLoading: false,
        searchError: action.payload,
      };
    default:
      return state;
  }
};
