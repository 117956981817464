import { combineReducers } from "redux";

import { errorReportReducer } from "./errorReporting/reducer";
import { questionsReducer } from "./questions/reducer";

const reducers = combineReducers({
  errorReporting: errorReportReducer,
  questions: questionsReducer,
});

export default reducers;
