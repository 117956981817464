import React, { Component } from "react";
import { connect } from "react-redux";

import { formatDate } from "../../../helper";
import { errorReportActions as actions } from "../../../redux/errorReporting/actions";
import { name, version } from "../../../../package.json";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.sendReport({
      date: formatDate(new Date()),
      message: error.message,
      name: error.name,
      stack: errorInfo.componentStack,
      url: document.location.href,
      version: `${name}: ${version}`,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <p style={{ marginTop: 40, marginLeft: 40 }}>Something went wrong.</p>
      );
    }
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => {
  return { sendReport: (data) => dispatch(actions.sendErrorReport(data)) };
};

export default connect(() => ({}), mapDispatchToProps)(ErrorBoundary);
