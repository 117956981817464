const prefix = "[Questions]";

const TYPES = {
  GET_QUESTIONS: `${prefix} GET_QUESTIONS`,
  GET_QUESTIONS_SUCCESS: `${prefix} GET_QUESTIONS_SUCCESS`,
  GET_QUESTIONS_ERROR: `${prefix} GET_QUESTIONS_ERROR`,
  GET_QUESTION: `${prefix} GET_QUESTION`,
  GET_QUESTION_SUCCESS: `${prefix} GET_QUESTION_SUCCESS`,
  GET_QUESTION_ERROR: `${prefix} GET_QUESTION_ERROR`,
  GET_QUESTIONS_BY_TAG: `${prefix} GET_QUESTIONS_BY_TAG`,
  GET_QUESTIONS_BY_TAG_SUCCESS: `${prefix} GET_QUESTIONS_BY_TAG_SUCCESS`,
  GET_QUESTIONS_BY_TAG_ERROR: `${prefix} GET_QUESTIONS_BY_TAG_ERROR`,
  GET_SEARCH_RESULT: `${prefix} GET_SEARCH_RESULT`,
  GET_SEARCH_RESULT_SUCCESS: `${prefix} GET_SEARCH_RESULT_SUCCESS`,
  GET_SEARCH_ERROR: `${prefix} GET_SEARCH_ERROR`,
};

export const questionsActions = {
  ...TYPES,
  getQuestions: (data) => ({
    type: TYPES.GET_QUESTIONS,
    payload: data,
  }),
  getQuestionsSuccess: (data) => ({
    type: TYPES.GET_QUESTIONS_SUCCESS,
    payload: data,
  }),
  getQuestionsError: (error) => ({
    type: TYPES.GET_QUESTIONS_SUCCESS,
    payload: error,
  }),
  getQuestionById: (data) => ({
    type: TYPES.GET_QUESTION,
    payload: data,
  }),

  getQuestionByIdSuccess: (data) => ({
    type: TYPES.GET_QUESTION_SUCCESS,
    payload: data,
  }),
  getQuestionByIdError: (error) => ({
    type: TYPES.GET_QUESTION_ERROR,
    error,
  }),
  getQuestionsByTag: (res) => ({
    type: TYPES.GET_QUESTIONS_BY_TAG,
    payload: res,
  }),

  getQuestionsByTagSuccess: (data) => ({
    type: TYPES.GET_QUESTIONS_BY_TAG_SUCCESS,
    payload: data,
  }),
  getQuestionsByTagError: (error) => ({
    type: TYPES.GET_QUESTIONS_BY_TAG_ERROR,
    error,
  }),
  getSearchResults: (res) => ({
    type: TYPES.GET_SEARCH_RESULT,
    payload: res,
  }),
  getSearchResultsSuccess: (data) => ({
    type: TYPES.GET_SEARCH_RESULT_SUCCESS,
    payload: data,
  }),
  getSearchResultsError: (error) => ({
    type: TYPES.GET_SEARCH_ERROR,
    error,
  }),
};
