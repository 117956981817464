const prefix = "[Error report]";

const TYPES = {
  SEND_ERROR_REPORT: `${prefix} GET_ORDER_ITEMS`,
  SEND_ERROR_REPORT_SUCCESS: `${prefix} SEND_ERROR_REPORT_SUCCESS`,
  SEND_ERROR_REPORT_ERROR: `${prefix} SEND_ERROR_REPORT_ERROR`,
};

export const errorReportActions = {
  ...TYPES,
  sendErrorReport: (data) => ({
    type: TYPES.SEND_ERROR_REPORT,
    payload: data,
  }),
  sendErrorReportSuccess: (res) => ({
    type: TYPES.SEND_ERROR_REPORT_SUCCESS,
    payload: res,
  }),
  sendErrorReportError: (error) => ({
    type: TYPES.SEND_ERROR_REPORT_ERROR,
    payload: error,
  }),
};
