import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastProvider } from "../components/common/toast";
import Analytics from "react-router-ga";
import { GA_TRACKING_ID } from "../constants/analytics";
import config from "../config";
import "./App.css";

const ViewMain = React.lazy(() => import("../pages/questions"));
const ViewQuestion = React.lazy(() => import("../pages/question"));
const ViewTag = React.lazy(() => import("../pages/tag"));
const ViewError = React.lazy(() => import("../pages/error"));

function App() {
  const helmetContext = {};
  const GA_ID = config?.env === "production" ? GA_TRACKING_ID : "";

  return (
    <HelmetProvider context={helmetContext}>
      <Suspense fallback={<></>}>
        <ToastProvider>
          <Router>
            <Analytics id={GA_ID}>
              <Switch>
                <Route
                  path="/:question_title/q/:id"
                  render={(props) => <ViewQuestion {...props} />}
                />
                <Route
                  path="/:tag_title/t/:id"
                  render={(props) => <ViewTag {...props} />}
                />
                <Route
                  path="/:tag_title/t/:id?page=:page"
                  render={(props) => <ViewTag {...props} />}
                />
                <Route
                  path="/questions?page=:page"
                  render={(props) => <ViewMain {...props} />}
                />
                <Route path="/" render={(props) => <ViewMain {...props} />} />
                <Route
                  path="/error"
                  render={(props) => <ViewError {...props} />}
                />
                <Redirect to="/error" />
              </Switch>
            </Analytics>
          </Router>
        </ToastProvider>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
