import { all, call, put, takeEvery } from "redux-saga/effects";
import { sendErrorReportAPI } from "./api";
import { errorReportActions as actions } from "./actions";

function* sendErrorReport({ payload }) {
  try {
    const { data: response } = yield call(sendErrorReportAPI, payload);
    yield put(actions.sendErrorReportSuccess(response));
  } catch (error) {
    yield put(actions.sendErrorReportError(error));
  }
}

export default function* errorReportingSagas() {
  yield all([takeEvery(actions.SEND_ERROR_REPORT, sendErrorReport)]);
}
