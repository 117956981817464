import React, { useRef, useEffect } from "react";
import styled, { css } from "styled-components";

const StyledToast = styled.div`
  font-size: 16px;
  background: #ffdddd;
  cursor: pointer;
  font-size: 14px;
  margin: 10px;
  padding: 15px 20px;
  color: #af0000;
  border: 1px solid #af0000;
  border-radius: 2px;

  ${(props) => {
    if (props.type === "success")
      return css`
        color: #067346;
        border: 1px solid #067346;
        background: #ddffe7;
      `;
  }}
`;

const Ctx = React.createContext();

const ToastContainer = (props) => (
  <div
    style={{ position: "fixed", right: 0, top: "50px", zIndex: 10000 }}
    {...props}
  />
);
const Toast = ({ children, onDismiss }) => {
  const removeRef = useRef();
  removeRef.current = onDismiss;

  useEffect(() => {
    const duration = 9000;
    const id = setTimeout(() => removeRef.current(), duration);

    return () => clearTimeout(id);
  }, []);

  return (
    <StyledToast type={children.type} onClick={onDismiss}>
      {children.message}
    </StyledToast>
  );
};

let toastCount = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const add = (content) => {
    const id = toastCount++;
    const toast = { content, id };
    setToasts([...toasts, toast]);
  };
  const remove = (id) => {
    const newToasts = toasts.filter((t) => t.id !== id);
    setToasts(newToasts);
  };

  const onDismiss = (id) => () => remove(id);

  return (
    <Ctx.Provider value={{ add, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ content, id, ...rest }) => (
          <Toast key={id} Toast={Toast} onDismiss={onDismiss(id)} {...rest}>
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);
