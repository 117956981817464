const {
  REACT_APP_API_HOST,
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL,
  REACT_APP_MPMS_TOKEN,
} = process.env;

const defaultConfig = {
  api: "https://api.testing.muntra.com/api",
  errorNotificationApi:
    "https://app.testing.muntra.com/log-api/error-notifications",
  mpmsToken: "YZmwhJ9UU7LiTL6dsixP91HlG57TYtXpSwrldupmjKykX5a82qy6Tza1WGn9i1we",
};

const api =
  REACT_APP_API_HOST || window.ENV?.REACT_APP_API_HOST || defaultConfig.api;
const errorNotificationApi =
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  window.ENV?.REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  defaultConfig.errorNotificationApi;
const mpmsToken =
  REACT_APP_MPMS_TOKEN ||
  window.ENV?.REACT_APP_MPMS_TOKEN ||
  defaultConfig.mpmsToken;

const config = {
  api,
  errorNotificationApi,
  mpmsToken,
};

export default config;
