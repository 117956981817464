import { all, call, put, takeLatest } from "redux-saga/effects";

import { NETWORK_ERROR } from "../../constants/errorText";
import {
  fetchQuestionsAPI,
  fetchQuestionByIdApi,
  fetchSearchResultAPI,
} from "./api";
import { getResults, getItemFields } from "../../helper";
import { questionsActions as actions } from "./actions";

function* getQuestions({ payload }) {
  try {
    const data = yield call(fetchQuestionsAPI, payload);
    if (!data) {
      yield put(actions.getQuestionsError());
    }

    const results = getResults(data.data.data, data.data.included);
    const questions = { results, ...data.data.meta };

    yield put(actions.getQuestionsSuccess(questions));
  } catch (error) {
    yield put(actions.getQuestionsError(error));
  }
}

function* getQuestionById({ payload }) {
  try {
    const data = yield call(fetchQuestionByIdApi, payload);
    if (!data) {
      yield put(actions.getQuestionByIdError(NETWORK_ERROR));
    }
    const { relationships, attributes } = data.data.data;
    const { included } = data.data;
    const results = getItemFields(relationships, included);
    const question = { attributes, ...results };

    yield put(actions.getQuestionByIdSuccess(question));
  } catch (error) {
    yield put(actions.getQuestionByIdError(error));
  }
}

function* getQuestionsByTag({ payload }) {
  try {
    const data = yield call(fetchQuestionsAPI, payload);
    if (!data) {
      yield put(actions.getQuestionsByTagError(NETWORK_ERROR));
    }
    const results = getResults(data.data.data, data.data.included);
    const questions = { results, ...data.data.meta };

    yield put(actions.getQuestionsByTagSuccess(questions));
  } catch (error) {
    yield put(actions.getQuestionsByTagError(error));
  }
}

function* getSearchResults({ payload }) {
  try {
    const data = yield call(fetchSearchResultAPI, payload);
    if (!data.data) {
      yield put(actions.getSearchResultsError());
    }

    yield put(actions.getSearchResultsSuccess(data.data.data));
  } catch (error) {
    yield put(actions.getSearchResultsError(error));
  }
}

export default function* questionsSagas() {
  yield all([
    takeLatest(actions.GET_QUESTIONS, getQuestions),
    takeLatest(actions.GET_QUESTION, getQuestionById),
    takeLatest(actions.GET_QUESTIONS_BY_TAG, getQuestionsByTag),
    takeLatest(actions.GET_SEARCH_RESULT, getSearchResults),
  ]);
}
