import { format, utcToZonedTime } from "date-fns-tz";
import sv from "date-fns/locale/sv";

export const slugifyer = (text) => {
  const mapObj = {
    å: "a",
    ä: "a",
    ö: "o",
    é: "e",
    ü: "u",
    ç: "c",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ñ: "n",
    ø: "o",
    ó: "o",
    ò: "o",
    ô: "o",
    õ: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ý: "y",
    ÿ: "y",
    æ: "ae",
    œ: "oe",
  };
  text = text
    .toLowerCase()
    .trim()
    .replace(
      /(?:å|ä|ö|é|ü|ç|è|ê|ë|í|ì|ï|ñ|ø|ó|ò|ô|õ|ú|ù|û|ý|ÿ|æ|œcatch)/gm,
      (matched) => mapObj[matched]
    );

  text = text.replace(/\s+|-{2,}|\//gm, "-").replace(/\s+|-{2,}|\//gm, "-");

  text = text.replace(/[^a-z0-9\s-]+|^-+|-+$/gm, ``);

  return text;
};

export const getIncludedItem = (included, search) =>
  included.find((item) => item.type === search.type && item.id === search.id);

const isObjectCondition = (item) => item?.data && typeof item.data === "object";
const isArrayCondition = (item) => item?.data && Array.isArray(item.data);

const operateObject = ({ result, key, included, relationships }) => {
  const includedItem = getIncludedItem(included, relationships[key].data);

  if (includedItem?.relationships) {
    const params = {
      result: includedItem,
      included: included,
      relationships: includedItem.relationships,
    };

    for (const key2 in includedItem.relationships) {
      if (isArrayCondition(includedItem.relationships[key2])) {
        operateArray({ ...params, key: key2 });
      } else if (isObjectCondition(includedItem.relationships[key2])) {
        operateObject({ ...params, key: key2 });
      } else includedItem[key2] = undefined;
    }
  }

  result[key] = includedItem;
};

const operateArray = ({ result, key, included, relationships }) => {
  result[key] = relationships[key].data.map((item) => {
    const includedItem = getIncludedItem(included, item);
    if (includedItem.relationships) {
      const params = {
        result: includedItem,
        included: included,
        relationships: includedItem.relationships,
      };

      for (const key2 in includedItem.relationships) {
        if (isArrayCondition(includedItem.relationships[key2])) {
          operateArray({ ...params, key: key2 });
        } else if (isObjectCondition(includedItem.relationships[key2])) {
          operateObject({ ...params, key: key2 });
        } else includedItem[key2] = undefined;
      }
    }
    return includedItem;
  });
};

export const getItemFields = (relationships, included) => {
  try {
    const result = {};
    const params = { result, included, relationships };
    for (const key in relationships) {
      if (isArrayCondition(relationships[key])) {
        operateArray({ ...params, key });
      } else if (isObjectCondition(relationships[key])) {
        operateObject({ ...params, key });
      } else result[key] = undefined;
    }

    return result;
  } catch (err) {
    console.log(err);
  }
};

export const formatDate = (date, dateFormat) => {
  const constructDate = new Date(date);
  const nyTimeZone = "Europe/Stockholm";

  const nyDate = utcToZonedTime(constructDate, nyTimeZone);

  if (!formatDate) return "";

  if (!dateFormat) {
    const nyDateWithoutFormat = utcToZonedTime(date, nyTimeZone);
    const finDateWithoutFormat = format(
      nyDateWithoutFormat,
      "yyyy-MM-dd'T'HH:mm:ssXXX",
      {
        timeZone: "Europe/Stockholm",
        locale: sv,
      }
    );
    return finDateWithoutFormat;
  }

  const finalDate = format(nyDate, dateFormat, {
    timeZone: "Europe/Stockholm",
    locale: sv,
  });

  return finalDate;
};

export const getResults = (arr, included) =>
  arr.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, included);
    return { ...result, ...rest };
  });
