import { errorReportActions as actions } from "./actions";

const initialState = {
  data: {},
  emailSettingsRes: {},
  error: "",
  loading: false,
  locale: {},
  res: {},
};

export const errorReportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.SEND_ERROR_REPORT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case actions.SEND_ERROR_REPORT_SUCCESS: {
      return {
        ...state,
        res: action.payload,
      };
    }
    case actions.SEND_ERROR_REPORT_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
